<template>
  <v-row class="pr-0 py-5">
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="2" lg="2" class="pb-0">
          <analytic-card
            title="Miembros Activos"
            class="colorAccent1"
            :description="indicators.activeMembers"
          />
        </v-col>
        <v-col cols="12" md="2" lg="2" class="pb-0">
          <analytic-card
            title="Miembros Inactivos"
            class="colorAccent2z<"
            :description="indicators.inactiveMembers"
          />
        </v-col>
        <v-col cols="12" md="2" lg="2" class="pb-0">
          <analytic-card
            title="Miembros Suspendidos"
            class="colorAccent3"
            :description="indicators.suspendedMembers"
          />
        </v-col>
        <v-col cols="12" md="2" lg="2" class="pb-0">
          <analytic-card
            title="Miembros Restringidos"
            class="colorAccent4"
            :description="indicators.restrictedMembers"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-row class="mt-5 px-5">
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 10 : 5">
        <div class="searchbar d-flex flex-row my-4">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                small
                elevation="0"
                :loading="searchBarLoader"
                class="rounded-pill rounded-r-0"
                color="#feb300"
                @click="filter(), (eventsSearchData.name = '')"
              >
                <v-icon size="20">mdi-magnify</v-icon></v-btn
              >
            </template>
            Clic para buscar un miembro
          </v-tooltip>
          <input
            :style="
              $vuetify.breakpoint.smAndDown ? ' width: 17rem ' : 'width:35rem'
            "
            type="text"
            placeholder="Buscar miembro"
            class="busqueda rounded-pill rounded-l-0"
            v-model="eventsSearchData.name"
            v-on:keypress.enter="
              filter(), (eventsSearchData.name = '')
            "
          />
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 10 : 2">
        <div class="searchbar d-flex flex-row my-4"
          :style="'height: 42px;'">
          <v-select 
            :items="items_validate_status"
            :style="
              $vuetify.breakpoint.smAndDown ? ' width: 15rem ' : 'width:35rem'
            "
            clearable
            class="busqueda my-0 py-0 pl-2 pr-5"
            placeholder="Estatus"
            v-model="eventsSearchData.validationStatus"
            @change="filter()"
          />
        </div>
        
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 10 : 2">
        <div class="searchbar d-flex flex-row my-4"
          :style="'height: 42px;'">
            <v-select
              :items="items_roles"
              :style="
                $vuetify.breakpoint.smAndDown ? ' width: 15rem ' : 'width:35rem'
              "
              clearable
              class="busqueda my-0 py-0 pl-2 pr-5"
              placeholder="Rol"
              v-model="eventsSearchData.role"
              @change="filter()"
            />
        </div>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.smAndDown ? 2 : 3"
        class="d-flex align-center"
        :class="
          $vuetify.breakpoint.smAndDown ? 'justify-end' : 'justify-center'
        "
      >
        <v-btn
          @click="open"
          color="accent"
          :small="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''"
          :fab="$vuetify.breakpoint.smAndDown"
          :disabled="
            !user.permissions.Members || !user.permissions.Members.create
          "
        >
          <v-icon v-if="$vuetify.breakpoint.smAndDown"> mdi-plus </v-icon>
          <span v-else class="font-weight-bold black--text"
            >+ Nuevo miembro</span
          >
        </v-btn>
      </v-col>
    </v-row>

    <!-- popup -->
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-row>
                <v-col cols="12" class="px-8 pt-5">
                  <h2 class="wDarkerBlue--text font-weight-black tittlePermit">
                    Agregar nuevo miembro/editar miembro
                  </h2>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="d-flex justify-left">
                  <span class="font-weight-bold bluetext titlepop"
                    >Datos de identificación</span
                  >
                </v-col>
                <!-- Text field -->
                <v-col cols="6">
                  <v-text-field
                    v-model="memberData.company"
                    label="Razón social"
                    placeholder="ej"
                    filled
                    rounded
                    :rules="companyRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="RFC"
                    placeholder="QWDERTGFOP1234"
                    filled
                    rounded
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Nombres del titular"
                    placeholder="Alejandro"
                    filled
                    rounded
                    v-model="memberData.name"
                    :rules="nameRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Apellidos del titular"
                    placeholder="Muñoz Castillo"
                    filled
                    rounded
                    v-model="memberData.firstLastname"
                  >
                  </v-text-field>
                  <!-- Combobox -->
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    :items="region"
                    label="Región"
                    filled
                    rounded
                    v-model="memberData.region"
                  ></v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    :items="branches"
                    label="Sección/Capítulo/Sucursal"
                    filled
                    rounded
                    item-value="text"
                    @change="(v) => setBranch(v)"
                    v-model="memberData.branch"
                  >
                  </v-combobox>
                </v-col>

                <!-- Seccion 2 popup -->
                <v-col cols="12" class="d-flex justify-left">
                  <span class="font-weight-bold bluetext titlepop"
                    >Datos de ingreso</span
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="E-Mail"
                    placeholder="example@wechamber.com"
                    filled
                    rounded
                    v-model="memberData.email"
                    :rules="emailRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Contraseña"
                    :type="showPass ? 'text' : 'password'"
                    filled
                    rounded
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    :rules="passwordRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Confirmar Contraseña"
                    :type="showPass ? 'text' : 'password'"
                    filled
                    rounded
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    :rules="passwordRules"
                  ></v-text-field>
                </v-col>
                <!-- Seccion 3 popup -->
                <v-col cols="6" class="d-flex justify-left">
                  <span class="font-weight-bold bluetext titlepop"
                    >Datos de ingreso</span
                  >
                </v-col>
                <v-col cols="6" class="d-flex justify-right">
                  <router-link to="/permisos" class="font-weight-bold bluetext">
                    <v-icon class="bluetext"> add </v-icon>
                    <span> Crear nuevo documento </span>
                  </router-link>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col class="mt-18" cols="2">
                      <v-icon>cancel</v-icon>
                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                        icon="cancel"
                        label="Nombre del documento"
                        placeholder="Gráficos.docx"
                        filled
                        rounded
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    accept="image/*"
                    label="Adjuntar archivo"
                    filled
                    rounded
                  ></v-file-input>
                </v-col>

                <v-row class="d-flex justify-right">
                  <v-col cols="2">
                    <v-btn @click="close()">
                      <v-icon class="bluetext"> mdi-chevron-left </v-icon>
                      <span> Volver </span>
                    </v-btn>
                  </v-col>
                  <v-col cols="4"></v-col>
                  <v-col cols="4">
                    <v-btn
                      color="#141d30"
                      @click="save"
                      class="font-weight-bold textwhite spaceb"
                      :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''"
                    >
                      <v-icon class="bluetext"> check </v-icon>
                      Confirmar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Table -->
   <v-row class="displayW">
      <v-pagination
        v-model="eventsSearchData.page"
        :length="membersPaginated.totalPages || 1"
        @input="filtrarMiembros()"
        total-visible="10"
        style="width: 100%"
        class="my-2"
      >
      </v-pagination>
      <v-card class="displayW2">
        <v-list>
          <v-list-group
            v-for="item in arrListMemberInformation"
            :key="item._id"
            no-action
            :append-icon="$vuetify.breakpoint.smAndDown ? '' : '$expand'"
            style="border: 1px solid lightgray"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                    <v-row class="ma-0">
                      <v-col cols="auto">
                        <v-chip
                          :color="
                            item.validationStatus == 'ACTIVE'
                              ? '#90caf9'
                              : '#ef9a9a'
                          "
                          v-text="
                            item.validationStatus == 'ACTIVE'
                              ? 'Activo'
                              : 'Inactivo'
                          "
                          class="rounded-lg font-weight-bold"
                        />
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <span
                          class="ma-0"
                          style="font-weight: 800; text-transform: uppercase"
                          >{{ item.list.name || "N/D" }} -
                          {{ item.list.lastName || "N/D" }}</span
                        >
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span style="color: '#35404c'; font-weight: bold">
                            F. Expiración -
                          </span>
                          N/D
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span style="color: '#35404c'; font-weight: bold">
                            Razón Social -
                          </span>
                          {{ item.drawer.compania || "N/D" }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span style="color: '#35404c'; font-weight: bold"
                            >RFC - </span
                          >{{ item.drawer.rfc || "N/D" }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span style="color: '#35404c'; font-weight: bold"
                            >E-MAIL - </span
                          >{{
                            item.moreInfo.email || item.drawer.email || "N/D"
                          }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span style="color: '#35404c'; font-weight: bold"
                            >CAPÍTULO - </span
                          >{{ item.moreInfo.Capítulo || "N/D" }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- cuadro para visualizar o descargar documentos 8 4-->
                  <v-col
                    :cols="$vuetify.breakpoint.smAndDown ? 9 : 3"
                    class="d-flex align-center flex-row-reverse"
                  >
                    <v-dialog
                      transition="dialog-bottom-transition"
                      width="700"
                      style="border-radius: 0px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#b3e5fc"
                          class="text-capitalize"
                          style="font-size: 17px; font-weight: 800"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="mx-2"> mdi-folder-outline</v-icon>
                          Documentos
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card
                          :class="
                            $vuetify.breakpoint.smAndDown ? 'pa-9' : 'pa-14'
                          "
                          style="border-radius: 0px"
                          color="white"
                        >
                          <v-row class="mb-5">
                            <v-col cols="auto" class="py-2">
                              <v-chip
                                :color="
                                  item.validationStatus == 'ACTIVE'
                                    ? '#90caf9'
                                    : '#ef9a9a'
                                "
                                v-text="
                                  item.validationStatus == 'ACTIVE'
                                    ? 'Activo'
                                    : 'Inactivo'
                                "
                                class="rounded-lg font-weight-bold"
                              />
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <span
                                class="ma-0"
                                style="
                                  font-weight: 800;
                                  text-transform: uppercase;
                                "
                                >{{ item.list.name || "N/D" }} -
                                {{ item.list.lastName || "N/D" }}</span
                              >
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  style="color: '#35404c'; font-weight: bold"
                                >
                                  F. Expiración -
                                </span>
                                N/D
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  style="color: '#35404c'; font-weight: bold"
                                >
                                  Razón Social -
                                </span>
                                {{ item.drawer.compania || "N/D" }}
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  style="color: '#35404c'; font-weight: bold"
                                  >RFC - </span
                                >{{ item.drawer.rfc || "N/D" }}
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  style="color: '#35404c'; font-weight: bold"
                                  >E-MAIL - </span
                                >{{ item.moreInfo.email || "N/D" }}
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  style="color: '#35404c'; font-weight: bold"
                                  >CAPÍTULO - </span
                                >{{ item.moreInfo.Capítulo || "N/D" }}
                              </p>
                            </v-col>
                          </v-row>
                          <v-card-text class="px-0">
                            <span
                              style="
                                color: #35404c;
                                font-weight: 900;
                                font-size: 18px;
                              "
                              >Lista de documentos</span
                            >
                            <v-divider
                              class="mt-1"
                              style="
                                background: #35404c;
                                border: 1px solid #35404c;
                              "
                            />
                            <v-list>
                              <v-list-item-group>
                                <v-list-item
                                  dense
                                  v-for="doc in Object.entries(item.documents)"
                                  :key="doc"
                                >
                                  <v-list-item-content>
                                    <span
                                      v-text="
                                        doc[0] == 'ine'
                                          ? 'INE'
                                          : doc[0] == 'comprobanteDomicilio'
                                          ? 'COMPROBANTE DE DOMICILIO'
                                          : 'CONSTANCIA SITUACIÓN FISCAL'
                                      "
                                      style="
                                        font-size: 15px;
                                        color: #35404c;
                                        font-weight: 800;
                                      "
                                    ></span>
                                  </v-list-item-content>
                                  <v-list-item-action
                                    class="
                                      d-flex
                                      flex-row
                                      justify-space-between
                                    "
                                  >
                                    <v-dialog
                                      fullscreen
                                      hide-overlay
                                      transition="dialog-bottom-transition"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="doc[1] == null"
                                          small
                                          fab
                                          elevation="0"
                                          class="mr-2 rounded-pill"
                                        >
                                          <v-icon>mdi-eye-outline</v-icon>
                                        </v-btn>
                                      </template>
                                      <template v-slot:default="dialog">
                                        <v-btn
                                          v-if="
                                            $vuetify.breakpoint.smAndDown !=
                                            true
                                          "
                                          @click="dialog.value = false"
                                          bottom
                                          right
                                          absolute
                                          fab
                                          color="accent"
                                          style="
                                            position: absolute;
                                            bottom: 50px;
                                            right: 50px;
                                          "
                                          ><v-icon>mdi-close</v-icon></v-btn
                                        >
                                        <v-btn
                                          v-else
                                          @click="dialog.value = false"
                                          large
                                          bottom
                                          ><v-icon>mdi-close</v-icon></v-btn
                                        >
                                        <iframe
                                          type="application/pdf"
                                          :src="
                                            'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
                                            doc[1]
                                          "
                                          style="width: 100%; height: 100%"
                                        />
                                      </template>
                                    </v-dialog>

                                    <v-btn
                                      :disabled="doc[1] == null"
                                      :href="doc[1]"
                                      fab
                                      small
                                      rounded="pill"
                                      elevation="0"
                                    >
                                      <v-icon> mdi-download-outline </v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card-text>
                          <v-card-actions class="d-flex justify-end">
                            <v-btn
                              @click="dialog.value = false"
                              elevation="0"
                              style="font-size: 18px; font-weight: 800"
                              class="text-capitalize rounded-pill"
                            >
                              <v-icon color="#35404c">mdi-chevron-left</v-icon>
                              <span style="color: #35404c" class="pr-2"
                                >Volver</span
                              >
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.smAndDown ? 3 : 1"
                    class="d-flex justify-center align-center"
                  >
                    <v-btn
                      fab
                      small
                      @click="editItem(item)"
                      elevation="1"
                      :disabled="
                        !user.permissions.Members ||
                        !user.permissions.Members.update
                      "
                    >
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                    <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                  </v-col>
                </v-row>
              </v-list-item-content>
            </template>

            <v-row class="text-uppercase pa-2 pl-7"> 
              <v-col :cols="$vuetify.breakpoint.smAndDown?12:4" class="py-1" v-for="(item, value, index) in item.drawer" :key="index">
                <p 
                v-if="
                    value !== 'ine' &&
                    value !== 'comprobanteDomicilio' &&
                    value !== 'constanciaSituacionFiscal'
                  "
                >
                <span
                v-if="item_labels_name.hasOwnProperty(value)">
                <strong>{{(item_labels_name[value]).replaceAll("_", " ").toUpperCase() || "N/D"}}</strong><br> {{item || 'N/D'}}
                </span>
                <span v-else>
                  <strong>{{value.replaceAll("_", " ").toUpperCase() || "N/D"}}</strong><br> {{item || 'N/D'}}
                </span>
              </p>
              </v-col>

              <v-col :cols="$vuetify.breakpoint.smAndDown?12:4" class="py-1">
                <p>
                  <span> <strong>Limite de submiembros</strong><br> {{item.moreInfo.sub_member_limit || '0'}}</span>
                </p>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown?12:4" class="py-1">
                <p>
                  <span> <strong>submiembros utilizados</strong><br> {{item.moreInfo.sub_members_used || '0'}}</span>
                </p>
              </v-col>
            </v-row>


            <!-- <v-list-item class="box">
              <v-list-item-content
                v-for="(item, value, index) in item.drawer"
                v-bind:key="index"
                style="width: 100%"
              >
                <v-list-item-title
                  v-if="
                    value !== 'ine' &&
                    value !== 'comprobanteDomicilio' &&
                    value !== 'constanciaSituacionFiscal'
                  "
                  style="padding-left: 25px; margin: 0"
                >
                  <b style="color: '#35404c'; font-weight: bold">{{
                    value.replaceAll("_", " ").toUpperCase() || "N/D"
                  }}</b
                  >- {{ item || "N/D" }}<br
                /></v-list-item-title>
              </v-list-item-content>

              <v-list-item-conetent style="width: 100%">
                <v-list-item-title style="padding-left: 25px">
                  <b
                    style="color: '#35404c'; font-weight: bold"
                    class="text-uppercase"
                    >Limite de submiembros
                  </b>
                  - {{ item.moreInfo.sub_member_limit || 0 }} <br />
                </v-list-item-title>
              </v-list-item-conetent>

              <v-list-item-conetent style="width: 100%">
                <v-list-item-title style="padding-left: 25px">
                  <b
                    style="color: '#35404c'; font-weight: bold"
                    class="text-uppercase"
                    >Sub miembros utilizados
                  </b>
                  - {{ item.moreInfo.sub_members_used || 0 }} <br />
                </v-list-item-title>
              </v-list-item-conetent>
            </v-list-item> -->
          </v-list-group>
        </v-list>
      </v-card>
    </v-row>
  </v-row>
</template>

<script>
import ResizeText from "vue-resize-text";
import { validations } from "@/share/validations.js";
import AnalyticCard from "@/components/dashboard/event/analytics/analytic-card";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  directives: {
    ResizeText,
  },
  components: {
    AnalyticCard,
  },
  props: ["chapterId"],
  computed: {
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("members", ["members", "membersPaginated"]),
    ...mapState("user", ["user"]),
  },
  data: () => ({
    dialog: false,
    searchBarLoader: false,
    activator: null,
    attach: null,
    editing: null,
    showPass: false,
    editingIndex: -1,
    indicators: {
      activeMembers: "0",
      inactiveMembers: "0",
      suspendedMembers: "0",
      restrictedMembers: "0",
    },
    items: [
      {
        action: "mdi-ticket",
        items: [{ title: "List Item" }],
        title: "Attractions",
      },
      {
        action: "mdi-silverware-fork-knife",
        active: true,
        items: [
          { title: "Breakfast & brunch" },
          { title: "New American" },
          { title: "Sushi" },
        ],
        title: "Dining",
      },
      {
        action: "mdi-school",
        items: [{ title: "List Item" }],
        title: "Education",
      },
      {
        action: "mdi-human-male-female-child",
        items: [{ title: "List Item" }],
        title: "Family",
      },
      {
        action: "mdi-bottle-tonic-plus",
        items: [{ title: "List Item" }],
        title: "Health",
      },
      {
        action: "mdi-briefcase",
        items: [{ title: "List Item" }],
        title: "Office",
      },
      {
        action: "mdi-tag",
        items: [{ title: "List Item" }],
        title: "Promotions",
      },
    ],
    item_labels_name:{
      'validationStatus': 'Estatus',
      'company': 'compañía',
      'createdAt':'Fecha de creación',
      'updatedAt': 'Fecha de actualización',
      'gender':'Género',
      'firstLastName':'Apellido paterno',
      'maximum_degree_of_studies': 'Grado maximo de estudios',
      'pagina_web':'Web',
      'correo_electronico_de_contacto': 'Correo'
    },
    items_validate_status: [
      { text: "Prospecto", value:'PROSPECTO' }, 
      { text: "Activo", value:'ACTIVE' }, 
      { text: "Inactivo", value:'INACTIVO' }
    ],
    items_roles: [
      { text: "Asociado", value: "MEMBER" },
      { text: "Afiliado", value: "MEMBERSHIP_USER" },
    ],
    region: [
      {
        text: "Norte",
      },
      {
        text: "Centro",
      },
      {
        text: "Sur",
      },
    ],
    headers: [
      { text: "", value: "checkbox", sortable: false },
      { text: "", value: "validationStatus", sortable: false },
      { text: "", value: "name", sortable: false },
      {
        text: " ",
        align: "start",
        value: "email",
        sortable: false,
      },
      { text: "", value: "company", sortable: false },
      { text: " ", value: "branch", sortable: false },
      { text: " ", value: "actions", sortable: false },
    ],
    memberBranch: "",
    branches: [],
    nonce: 1,
    menu: false,
    model: [
      {
        text: "AMPI - Guanajuato ",
      },
    ],
    nameRules: validations.nameValidation({ name: "nombre", required: true }),
    companyRules: validations.nameValidation({
      name: "company",
      required: true,
    }),
    firstLastnameRules: validations.nameValidation({
      name: "primer apellido",
      required: true,
    }),
    emailRules: validations.emailValidation(),
    passwordRules: validations.generalFValidation({
      required: true,
      minLength: 8,
      name: "contraseña",
    }),
    memberData: {
      id: "",
      name: "",
      firstLastname: "",
      email: "",
      company: "",
      region: "",
      branch: "",
    },
    boolEdit: false,
    x: 0,
    search: null,
    y: 0,
    eventsSearchData: {
      name: "",
      branch: "",
      role: "",
      page: 1,
      perPage: 10,
      validationStatus: ""
    },
    dialogDelete: false,
    alertBool: false,
    drawerMemberInformation: [],
    listMemberInformation: [],
    arrListMemberInformation: [],
    moreMemberInformation: [],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },

  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("members", ["clearmembersPaginated"]),
    ...mapActions("members", [
      "getMembers",
      "createMember",
      "getChapterIndicators",
      "getBranchMembers",
      "getBranchMembersPaginated",
      "filterBranchMembersPaginated",
      "updateMember",
      "deleteMember",
    ]),
    ...mapActions("branches", ["getBranches"]),

    editItem(member) {
      this.dialog = true;
      this.memberData = member;
      this.boolEdit = true;
    },
    deleteItem(id) {
      this.dialogDelete = true;
      this.memberData.id = id;
    },

    async deleteItemConfirm() {
      this.loading();
      let id = this.memberData.id;
      const response = await this.deleteMember(id);

      if (response.status === true) {
        this.closeDelete();
        this.memberData.id = "";
        this.loaded();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
    openDetail() {
      this.dialog = true;
    },

    nextPage(page) {
      this.eventsSearchData.page = page;
    },

    async fetchMembers() {
      this.loading();
      this.eventsSearchData.branch = this.chapterId;
      if (this.eventsSearchData.name === "") {
        const response = await this.getBranchMembers(this.eventsSearchData);
        console.log(response)
        this.arrListMemberInformation = [];
        if (response.status == true) {
          if (this.membersPaginated.members) {
            this.membersPaginated.members.forEach((m) => {
              if (m.memberInformation) {
              this.listMemberInformation = {
                name: m.memberInformation.name,
                lastName: m.memberInformation.lastName
              };

                this.moreMemberInformation = {
                  Capítulo: m.branch.company,
                  email: m.memberInformation.email,
                  sub_member_limit: m.memberInformation.sub_member_limit,
                  sub_members_used: m.memberInformation.sub_members_used,
                };
                //this.moreMemberInformation.Capítulo = m.branch.company;

                this.drawerMemberInformation = Object.keys(
                  m.memberInformation
                ).reduce((result, key) => {
                  !Object.keys(this.listMemberInformation).includes(key) &&
                  !Object.keys(this.moreMemberInformation).includes(key)
                    ? (result[key] = m.memberInformation[key])
                    : "";
                  return result;
                }, {});

                this.memberDocuments = {
                  ine: this.drawerMemberInformation.ine,
                  comprobanteDomicilio:
                    this.drawerMemberInformation.comprobanteDomicilio,
                  constanciaSituacionFiscal:
                    this.drawerMemberInformation.constanciaSituacionFiscal,
                };

              this.arrListMemberInformation.push({
                company: m.company,
                validationStatus: m.validationStatus,
                _id: m._id,
                list: this.listMemberInformation,
                drawer: this.drawerMemberInformation,
                moreInfo: this.moreMemberInformation,
                documents: this.memberDocuments,              
                allInformation: m
              });
              } 
            });
            this.loaded();
          }
        }
      } else {
        const response = await this.getBranchMembersPaginated(
          this.eventsSearchData
        );
        this.arrListMemberInformation = [];

        if (response.status == true) {
          if (this.membersPaginated.members) {
            this.membersPaginated.members.forEach((m) => {
              if (m.memberInformation) {
                this.listMemberInformation = {
                  name: m.memberInformation.name,
                  lastName: m.memberInformation.lastName,
                };

                this.moreMemberInformation = {
                  Capítulo: m.branch.company,
                  email: m.memberInformation.email,
                  sub_member_limit: m.memberInformation.sub_member_limit,
                  sub_members_used: m.memberInformation.sub_members_used,
                };

                this.drawerMemberInformation = Object.keys(
                  m.memberInformation
                ).reduce((result, key) => {
                  !Object.keys(this.listMemberInformation).includes(key) &&
                  !Object.keys(this.moreMemberInformation).includes(key)
                    ? (result[key] = m.memberInformation[key])
                    : "";
                  return result;
                }, {});

                this.memberDocuments = {
                  ine: this.drawerMemberInformation.ine,
                  comprobanteDomicilio:
                    this.drawerMemberInformation.comprobanteDomicilio,
                  constanciaSituacionFiscal:
                    this.drawerMemberInformation.constanciaSituacionFiscal,
                };

              this.arrListMemberInformation.push({
                company: m.company,
                validationStatus: m.validationStatus,
                _id: m._id,
                list: this.listMemberInformation,
                drawer: this.drawerMemberInformation,
                moreInfo: this.moreMemberInformation,
                documents: this.memberDocuments,              
                allInformation: m
              });
              }
            });
            this.loaded();
          }
        }
      }
    },

    async fetchIndicators() {
      const response = await this.getChapterIndicators(this.chapterId);

      if (response.status == true) {
        console.log(response);
        this.indicators = {
          activeMembers: response.data.activeMembers,
          inactiveMembers: response.data.inactiveMembers,
          totalMembers: response.data.totalMembers,
        };
      }
    },

    filter(){
      this.eventsSearchData.page = 1;
      this.filtrarMiembros();
    },

    async filtrarMiembros() {
      this.searchBarLoader = true;
      this.eventsSearchData.branch = this.chapterId;
      this.clearmembersPaginated();
      const response = await this.getBranchMembersPaginated(
        this.eventsSearchData
      );

      if (response.status === true) {
        if (this.membersPaginated.members) {
          this.arrListMemberInformation = [];

          this.membersPaginated.members.forEach((m) => {
            if (m.memberInformation) {
              this.listMemberInformation = {
                name: m.memberInformation.name,
                lastName: m.memberInformation.lastName,
              };

              this.moreMemberInformation = {
                Capítulo: m.hasOwnProperty("branch") ? m.branch.company : "N/A",
                email: m.memberInformation.email,
                sub_member_limit: m.memberInformation.sub_member_limit,
                sub_members_used: m.memberInformation.sub_members_used,
              };

              this.drawerMemberInformation = Object.keys(
                m.memberInformation
              ).reduce((result, key) => {
                !Object.keys(this.listMemberInformation).includes(key) &&
                !Object.keys(this.moreMemberInformation).includes(key)
                  ? (result[key] = m.memberInformation[key])
                  : "";
                return result;
              }, {});

              this.arrListMemberInformation.push({
                company: m.company,
                validationStatus: m.validationStatus,
                _id: m._id,
                list: this.listMemberInformation,
                drawer: this.drawerMemberInformation,
                moreInfo: this.moreMemberInformation,
                documents: {
                  ine: this.drawerMemberInformation.ine,
                  comprobanteDomicilio:
                    this.drawerMemberInformation.comprobanteDomicilio,
                  constanciaSituacionFiscal:
                    this.drawerMemberInformation.constanciaSituacionFiscal,
                },
                allInformation: m,
              });
            }
          });
          this.loaded();
          this.searchBarLoader = false;
        }
      }
    },

    setBranch(v) {
      this.memberData.branch = {
        _id: v._id,
        company: v.text,
      };
    },

    async save() {
      this.loading();
      if (this.boolEdit === true) {
        const response = await this.updateMember(this.memberData);

        if (response.status === true) {
          this.close();
          this.boolEdit = false;
          this.loaded();
        }
      } else {
        this.memberData.region = this.memberData.region.text;

        const response = await this.createMember(this.memberData);

        if (response.status === true) {
          this.close();
          this.loaded();
        } else {
          this.close();
          this.loaded();
          this.alertBool = true;
        }
      }
    },
  },
  destroyed() {
    this.clearmembersPaginated();
  },
  async mounted() {
    await this.filter();
    await this.fetchIndicators();
  },
};
</script>

<style>
.colorAccent1 {
  background-color: #b3e5fc !important;
}

.colorAccent2 {
  background-color: #ffecb3 !important;
}

.colorAccent3 {
  background-color: #ffe0b2 !important;
}

.colorAccent4 {
  background-color: #cfd8dc !important;
}

.accent--text::before {
  color: #000000 !important;
}

.noShadow {
  box-shadow: none;
}

.tittlePermit {
  margin-top: 4%;
}

.bluetext {
  color: #141d30;
}

.textwhite {
  color: #ffffff !important;
}

.displayW {
  width: 100% !important;
}
.displayW2 {
  width: 100% !important;
  margin-top: 40px !important;
  margin-inline: 20px;
}
.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px !important;
}
.box > * {
  flex: 0 0 33.3333%;
}
/* iPhone */
@media (max-width: 575.98px) {
  b {
    font-size: 0.8em !important;
    white-space: normal;
  }
  .v-list-item__title {
    font-size: 0.8rem !important;
    white-space: normal;
  }
  .v-chip {
    font-size: 0.6rem !important;
    white-space: normal;
  }
}
.busqueda {
  padding-left: 20px;
  border: 0;
  border-color: transparent;
  background-color: #f9fafc;
}

.busqueda:focus {
  outline: none;
  border: 1px solid transparent;
}
</style>